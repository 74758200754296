import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { InitJson } from './init-json';

const API = {
  init: 'api/init',
};

@Injectable()
export class InitService {
  constructor(private http: HttpClient) {}

  getConfig(): Observable<InitJson> {
    return this.http
      .get<InitJson>(API.init)
      .pipe(catchError((error: HttpErrorResponse) => throwError(error.error.message || 'Server error')));
  }
}
