import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dm-boilerplate',
  templateUrl: './boilerplate.component.html',
  styleUrls: ['./boilerplate.component.scss'],
})
export class BoilerplateComponent implements OnInit {
  public visible: boolean;

  constructor() {}

  ngOnInit() {
    this.visible = false;
  }

  toggle(): void {
    this.visible = !this.visible;
  }
}
