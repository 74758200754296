import { InitService } from './config/init.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BoilerplateComponent } from './boilerplate/boilerplate.component';
import { HeiComponent } from './hei/hei.component';
import { StatComponent } from './stat/stat.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatButtonModule, MatCardModule],
  declarations: [BoilerplateComponent, HeiComponent, StatComponent],
  exports: [BoilerplateComponent, HeiComponent, StatComponent],

  providers: [InitService],
})
export class SiterepModule {}
