<main class="row no-gutters">
  <div class="col-lg-offset-1 col-lg-10 dm-viewport">
    <!-- Error view, the message retrieved from the server -->
    <div *ngIf="errorMessage">
      <div class="alert alert-danger" tabindex="0">
        <p><strong>Error!</strong> The following problem has occured:</p>
        <ul>
          <li>{{ errorMessage }}</li>
        </ul>
      </div>
      <p tabindex="0">
        If the problem persists, please contact the EDINA Helpdesk at
        <a href="mailto:digimap@ed.ac.uk">digimap@ed.ac.uk</a>
        to resolve this problem.
      </p>
      <button type="button" class="btn btn-success" (click)="navigate()">Continue</button>
    </div>

    <div *ngIf="!errorMessage">
      <h3 tabindex="0">Digimap site usage statistics</h3>
      <dm-boilerplate></dm-boilerplate>
      <dm-stat (initFailed)="onFailed($event)"></dm-stat>
    </div>
  </div>
</main>
