<div *ngIf="isEdinaUser">
  <!-- Only for Edina User -->
  <div class="row">
    <div class="col-xs-10 col-sm-6">
      <div class="form-group">
        <p>EDI users can choose which institution to query. Enter hei code to choose a different institution.</p>
      </div>
    </div>
  </div>

  <div [formGroup]="statForm">
    <div class="row">
      <div class="col-xs-10 col-sm-6">
        <div class="form-group">
          <input id="hei" name="hei" formControlName="hei" type="text" class="form-control" />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12">
        <div *ngIf="!statForm.controls['hei'].valid" class="form-group">
          <p *ngIf="statForm.controls['hei'].hasError('minlength')" class="alert alert-danger">
            Hei code is Too Short!
          </p>
          <p *ngIf="statForm.controls['hei'].hasError('maxlength')" class="alert alert-danger">Hei code is Too Long!</p>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!isEdinaUser" hidden>
  <!-- Academic Institution Users -->
  <div [formGroup]="statForm">
    <div class="row">
      <div class="col-xs-10 col-sm-6">
        <div class="form-group">
          <input id="hei" name="hei" formControlName="hei" type="text" class="form-control" />
        </div>
      </div>
    </div>
  </div>
</div>
