import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  templateUrl: 'siterep.component.html',
  styleUrls: ['siterep.component.scss'],
})
export class SiterepComponent {
  errorMessage: string;

  constructor(private router: Router) {}

  onFailed(message: string) {
    console.log('Form could not be build: ', message);

    this.errorMessage = message;
  }

  /**
   * Takes the user back to the home page.
   */
  navigate(): void {
    this.router.navigate(['/']);
  }
}
