/* eslint-disable @typescript-eslint/no-unused-vars */

import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'dm-hei',
  templateUrl: './hei.component.html',
  styleUrls: ['./hei.component.scss'],
})
export class HeiComponent {
  @Input() statForm: FormGroup;
  @Input() isEdinaUser: boolean;

  constructor() {}
}
