<mat-card *ngIf="initResults" class="panel-color">
  <form novalidate ngNoForm method="post" action="./api/siterep">
    <!--  Note: [formGroup]="statForm" must be in a tag that encloses all the form elements in order to use Reactive Form functionality.
          We can't place it in the <form> tag in our case because it will override the ngNoForm directive which allows us to post the form
          using an action on the form tag.
    -->
    <div [formGroup]="statForm">
      <div class="row">
        <div class="col-xs-10 col-sm-6">
          <div class="form-group">
            <label for="type">Type of statistic: </label>
            <select id="type" name="type" formControlName="type" class="form-control">
              <option *ngFor="let type of initResults.types" [value]="type.value">
                {{ type.text }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12">
          <div class="form-group">
            <div class="alert alert-danger" role="note">
              Please note:
              <ul class="note-list">
                <li>
                  <strong>Active Users by Status/Department</strong> cannot be selected by time period and only reflects
                  the current (at time of generation) situation.
                </li>
                <li>
                  If a list of the active users is required, please contact the
                  <a href="mailto:digimap@ed.ac.uk">edina helpdesk</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="!this.collectionAgnostic">
        <div class="col-xs-10 col-sm-6">
          <div class="form-group">
            <label for="collection">Collection: </label>
            <select id="collection" name="collection" formControlName="collection" class="form-control">
              <option *ngFor="let collection of initResults.collections" [value]="collection.value">
                {{ collection.text }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div *ngIf="!this.currentSnapshotOnly" role="radiogroup" aria-labelledby="time-period-label" id="period">
        <div class="row">
          <div class="col-xs-10 col-sm-6">
            <div class="form-group">
              <label id="time-period-label">Time Period: </label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-10 col-sm-6">
            <div class="form-group">
              <label class="radio-inline">
                <input name="period" formControlName="period" type="radio" value="MONTHLY" />
                Month
              </label>
              <label class="radio-inline">
                <input name="period" formControlName="period" type="radio" value="ANNUALLY" />
                Annual
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="!this.currentSnapshotOnly">
        <div class="col-xs-10 col-sm-6">
          <div class="form-group">
            <label for="year">Academic Year:</label>
            <select id="year" name="year" class="form-control" formControlName="year">
              <option *ngFor="let year of initResults.years" [value]="year.value">
                {{ year.text }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="!this.currentSnapshotOnly && this.monthlySnapshot">
        <div class="col-xs-3">
          <div class="form-group">
            <label for="month">Month:</label>
            <select id="month" name="month" formControlName="month" class="form-control">
              <option *ngFor="let month of initResults.months" [value]="month.value">
                {{ month.text }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-sm-6"></div>
      </div>

      <div class="row" *ngIf="!this.currentSnapshotOnly">
        <div class="col-xs-12">
          <div class="form-group">
            <div class="alert alert-danger">
              <p>
                If you require statistics for time periods earlier than the 2016-2017 academic year, please contact
                EDINA on <a href="mailto:digimap@ed.ac.uk">digimap@ed.ac.uk</a>.
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- HeiComponent -->
      <dm-hei [statForm]="statForm" [isEdinaUser]="isEdinaUser"></dm-hei>

      <div class="row">
        <div class="col-xs-10 col-sm-6">
          <div class="form-group">
            <p>Click to generate your CSV file, this can take some time for certain statistics.</p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-10 col-sm-6 col-sm-12">
          <div class="form-group">
            <input
              type="submit"
              id="submit"
              value="Generate"
              class="btn btn-lg btn-success"
              [disabled]="!statForm.valid" />
          </div>
        </div>
      </div>
    </div>
  </form>
</mat-card>
