import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InitService } from '../config/init.service';
import { InitJson } from '../config/init-json';

@Component({
  selector: 'dm-stat',
  templateUrl: './stat.component.html',
  styleUrls: ['./stat.component.scss'],
})
export class StatComponent implements OnInit {
  @Output() initFailed: EventEmitter<string>;

  public statForm: FormGroup;
  public initResults;
  public isEdinaUser: boolean;
  public currentSnapshotOnly: boolean;
  public collectionAgnostic: boolean;
  public monthlySnapshot: boolean;
  public userPeriod: string;

  private userMonth: number;
  private userYear: number;

  private userCollection: boolean;

  constructor(private fb: FormBuilder, private initService: InitService) {
    this.initFailed = new EventEmitter<string>();
  }

  ngOnInit() {
    this.initService.getConfig().subscribe(
      (result: InitJson) => {
        this.initResults = result;
        this.collectionAgnostic = this.initResults.types[0].collectionAgnostic;
        this.currentSnapshotOnly = this.initResults.types[0].currentSnapshotOnly;
        this.monthlySnapshot = !this.currentSnapshotOnly && this.initResults.defaultPeriod === 'MONTHLY';
        this.isEdinaUser = this.initResults.hei === this.initResults.specialHei;
        this.userMonth = this.initResults.months[0].value;
        this.userYear = this.initResults.years[0].value;
        this.userCollection = this.initResults.collections[0].value;
        this.initStatForm();
        this.onValueChanges();
      },
      (err: string) => {
        this.initFailed.emit(err);
      }
    );
  }

  // Create and initialise the FormGroup
  initStatForm() {
    this.statForm = this.fb.group({
      collection: [
        {
          value: this.initResults.collections[0].value,
          disabled: this.collectionAgnostic,
        },
        Validators.required,
      ],
      type: [this.initResults.types[0].value, [Validators.required]],
      period: [
        {
          value: this.initResults.defaultPeriod,
          disabled: this.currentSnapshotOnly,
        },
        Validators.required,
      ],
      month: [
        {
          value: this.initResults.months[0].value,
          disabled: !this.monthlySnapshot,
        },
        Validators.required,
      ],
      year: [
        {
          value: this.initResults.years[this.initResults.years.length - 1].value,
          disabled: this.currentSnapshotOnly,
        },
        Validators.required,
      ],
      hei: [this.initResults.hei, [Validators.required, Validators.maxLength(3), Validators.minLength(3)]],
    });
  }

  // Update state of 'month' FormControl based on type selection
  onValueChanges() {
    const typeAbstractControl: AbstractControl | null = this.statForm.get('type');
    if (typeAbstractControl) {
      typeAbstractControl.valueChanges.subscribe(selectedType => {
        if (selectedType) {
          this.userMonth = this.statForm.controls['month'].value;
          this.userPeriod = this.statForm.controls['period'].value;
          this.userYear = this.statForm.controls['year'].value;
          this.userCollection = this.statForm.controls['collection'].value;
          const index = this.initResults.types.findIndex(type => type.value === selectedType);
          this.currentSnapshotOnly = this.initResults.types[index].currentSnapshotOnly;
          this.collectionAgnostic = this.initResults.types[index].collectionAgnostic;
          // Enable or disable Period selection and reset original value chosen by user
          this.statForm.controls['period'].reset({ value: this.userPeriod, disabled: this.currentSnapshotOnly });
          // Enable or disable Month and reset original value  chosen by user
          this.statForm.controls['month'].reset({ value: this.userMonth, disabled: !this.monthlySnapshot });
          // Enable or disable Year and reset original value  chosen by user
          this.statForm.controls['year'].reset({ value: this.userYear, disabled: this.currentSnapshotOnly });
          // Enable or disable Collection and reset original value  chosen by user
          this.statForm.controls['collection'].reset({ value: this.userCollection, disabled: this.collectionAgnostic });
        }
      });
    }

    const periodAbstractControl: AbstractControl | null = this.statForm.get('period');
    if (periodAbstractControl) {
      periodAbstractControl.valueChanges.subscribe(selectedPeriod => {
        if (selectedPeriod) {
          this.userPeriod = selectedPeriod;
          this.monthlySnapshot = selectedPeriod === 'MONTHLY';
          // Enable or disable Month and reset original value  chosen by user
          this.statForm.controls['month'].reset({ value: this.userMonth, disabled: !this.monthlySnapshot });
          // Enable or disable Year and reset original value  chosen by user
          this.statForm.controls['year'].reset({ value: this.userYear, disabled: this.currentSnapshotOnly });
        }
      });
    }
  }

  /**
   * This method is called on form submission.
   * It returns false to prevent page refresh.
   *
   * @returns false
   * @memberof StatComponent
   */
  onSubmit(e) {
    return false;
  }
}
